var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-panel clearfix"},[_c('div',{staticClass:"login-layout-wrap"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.siteLoginHeader))]),_c('div',{staticClass:"login-info-wrap"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'username',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.$t('valid.error.not_empty')
                  }
                ]
              }
            ]),expression:"[\n              'username',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('valid.error.not_empty')\n                  }\n                ]\n              }\n            ]"}],attrs:{"autocomplete":"","name":"username","placeholder":_vm.$t('page.login.userName.hint')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"user"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'password',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.$t('valid.error.not_empty')
                  }
                ]
              }
            ]),expression:"[\n              'password',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('valid.error.not_empty')\n                  }\n                ]\n              }\n            ]"}],attrs:{"type":"password","name":"password","autocomplete":"","placeholder":_vm.$t('page.login.password.hint')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"lock"},slot:"prefix"})],1)],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'validatorCode',
              {
                rules: [
                  {
                    required: true,
                    message: _vm.$t('valid.error.not_empty')
                  }
                ]
              }
            ]),expression:"[\n              'validatorCode',\n              {\n                rules: [\n                  {\n                    required: true,\n                    message: $t('valid.error.not_empty')\n                  }\n                ]\n              }\n            ]"}],attrs:{"max-length":5,"placeholder":_vm.$t('page.login.vcode.hint')}},[_c('a-icon',{attrs:{"slot":"prefix","type":"safety-certificate"},slot:"prefix"}),_c('div',{staticClass:"vcode-image-wrap",attrs:{"slot":"suffix"},slot:"suffix"},[_c('img',{attrs:{"src":_vm.validateCodeImageUrl},on:{"click":_vm.regenerateVcode}})])],1)],1),_c('a-form-item',{staticClass:"login-btn-wrap"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.loadingBtn,"block":""}},[_vm._v(_vm._s(_vm.$t('page.login.login')))])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }